import React, { useEffect, useRef } from 'react'
import './qnaEmail.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import AOS from 'aos'
import "aos/dist/aos.css"
import { Link } from 'react-router-dom';

import emailjs from '@emailjs/browser';

const QnaEmail = () => {


  useEffect(() => {
    AOS.init(
    )
  })

 
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();


    // if (window.confirm("문의 접수 하시겠습니까?")) {
      if (window.confirm("준비중입니다. 전화 문의주세요 :)")) {
      

      // emailjs.sendForm('service_zmgfncd', 'template_ai8pw1i', form.current, 'wmVfTMio2apDYz8CC')
      // emailjs.sendForm(process.env.REACT_APP_SENDFROM_ONE  , process.env.REACT_APP_SENDFROM_TWO, form.current, process.env.REACT_APP_SENDFROM_THREE)
      //   .then((result) => {
      //     console.log(result.text);
      //     alert('발송완료');

      //   }, (error) => {
      //     console.log(error.text);
      //   });



    } else {

      return;
    }





  };







  return (
  

          <div className='container'  data-aos="fade-up" data-aos-duration="700"  data-aos-delay="200" >
              <div className='contact-box'>
                  <div className='content'>
                  <form ref={form} onSubmit={sendEmail}>
                      <label className='qnaText'>고객님 성함</label>
                      <input type="text" name="user_name" className='inputBox' />


                      <label  className='qnaText'>메일 주소</label>
                      <input type="text" name="user_phone" className='inputBox' />



                      <label  className='qnaText'>전화번호</label>
                      <input type="text" name="user_email" className='inputBox' />



                      <label  className='qnaText'>문의 내용</label>
                      <textarea name="message" className='inputBox' />

                      <input type="submit" value="준비중입니다. 전화문의 부탁드려요~!" className='clickbox' />

                      </form>
                  </div>
              </div>
          </div>
 
  )
}

export default QnaEmail
