import Navbar from './Navbar/Navbar'
import Bottom from './Bottom/bottom'
import Introduce from './components/introduce'
import Product from './components/product'
import Road from './components/road'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/main'
import SocialActive from './components/socialActive'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Gotop from './components/gotop'
import Bori from './components/bori'
import CompanyLine from './line/company'
import MainFirst from './components/mainFirst'
import Reference from './components/reference'
import QnaEmail from './components/qnaEmail'

import  { ContactUs } from './components/contactus'

import Review from './components/review'

import Star from './components/star'



function App() {
  return (
    <>
    <BrowserRouter>

      <Navbar />

      <MainFirst />


      {/* element로 라우트를 연결 해야함 */}
      <Element id="test" />

      <Introduce />

      <Element id="mallReview"/>
      <Star />

      <Element id="socialA" />
      <SocialActive />

      
      <Element id="reference" />
      <Reference />


      <Element id="bori" />
      <Bori />


      <Element id="qna" />
      
      <Road />

      <Bottom/>
    </BrowserRouter>
    </>
  );
}



export default App;
