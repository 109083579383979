import React, { useEffect } from 'react'
import styled from 'styled-components'
import AOS from 'aos'
import "aos/dist/aos.css"
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import './main.css'


const Main = () => {

  useEffect(() => {
    AOS.init(
      { duration: 650 }
    )
  })



  return (
    <>
    <div className = 'hero'>
      <div className = 'content'>

      <p>텍스트 입니다.</p>

      </div>
    </div>

    
    
    </>
        // <div data-aos="fade-up" className='bodyText'>

        //   <h2 > CCTV 안전한 삶을 설계하다 </h2>
        //   <h2>디네이션</h2>

        // </div>
  )


  
}

export default Main
