import React, { useEffect } from 'react'
import './road.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import AOS from 'aos'
import "aos/dist/aos.css"
import { Link } from 'react-router-dom';
import QnaEmail from './qnaEmail';


const Road = () => {


  useEffect(() => {
    AOS.init(
    )
  })





  const you = () => {
    window.open(`https://www.youtube.com/channel/UCfsDNqVF9G0F22WbvfY2noA`)
    
  }
  const cafe = () => {
    window.open(`https://cafe.naver.com/dnation`)
    
  }
  const blog = () => {
    window.open(`https://blog.naver.com/dw_nation`)
    
  }
  const store = () => {
    window.open(`https://smartstore.naver.com/camctv`)
    
  }
  const b2b = () => {
    window.open(`https://d-nation.kr/`)
    
  }


  return (
    <div className='roadFbody'>


    <div className='roadFbodyTitle'>
        <p  className='RoadWait' style = {{ textAlign : 'center'}}  data-aos="fade-up" data-aos-duration="1200"  data-aos-delay="0" >
          기다리고 있어요.</p>
          <p className='RoadWhen'  style = {{color : 'black', textAlign : 'center', margin : '30px'}} 
          data-aos="fade-up" data-aos-duration="700"  data-aos-delay="200" >
            언제든지 문의주세요!(서비스 준비중)</p>
    </div>


      <QnaEmail/>

      <Card style = {{backgroundColor : '#103F83', borderTopRightRadius : '50px', borderTopLeftRadius : '0px', borderBottomLeftRadius : '0px', borderBottomRightRadius : '0px',
      paddingTop : '10vh', paddingBottom : '10vh'}}  
      data-aos="fade-right" data-aos-duration="600"  data-aos-delay="400" 
      
      >


        <div className='roadContent'>

        


            <div className='roadContentCenter'>
         
          
            <Row  style={{display: 'flex', margin : '20px', backgroundColor : '#103F83'}}>
              <Col   md={8}  style = {{ }}>
                <p className='roadTitle'>디네이션 위치</p>



                 <div className='roadImg'  >
                    <img src="map_00.png"  className='roadImgMe'/> 
       
                    </div>
                <p className='roadEx'   style = {{ textAlign : 'left'}} >
                경기도 구리시 갈매동 552-12 구리갈매 금강펜테리움IX타워 타워동 9층 36호
                </p>
              </Col>




              <Col  md={3} style = {{ backgroundColor : '#103F83', width : '270px'}}  >
                <p className='roadLink'> 디네이션 채널 </p>

                 <div className='DnationSStore' onClick={b2b} >
                 <img src='./001.png' className='youtubeLogo' />
                 <span className = "dyoutube">CCTV 도매 전용몰</span> </div>

                {/* <Card style={{ marginBottom: '2vh', padding: '1vh', paddingTop: '2.2vh' }} >  */}
                 <div className='DnationSStore'  onClick={store}>
                 <img src='./smartstore_UI.png' className='youtubeLogo' />
                 <span className = "dSmart">디네이션 스마트 스토어</span> </div>
                 
                 {/* </Card>    */}
                 

                 <div className='DnationSStore' onClick={blog} >
                 <img src='./naverblog_UI.png' className='youtubeLogo' />
                 <span className = "dyoutube">디네이션 블로그</span> </div>

                 <div className='DnationSStore' onClick={cafe}>
                 <img src='./navercafe_UI.png' className='youtubeLogo' />
                 <span className = "dyoutube">디네이션 카페</span> </div>

                 <div className='DnationSStore' onClick={you}>
                    <img src='./youtube_UI_2.png' className='youtubeLogo' />
                    <span className="dyoutube"> 디네이션 유튜브  </span>
                  </div>
                 
                 {/* <Card style = {{marginBottom : '2vh', padding : '1vh', paddingTop : '2.2vh'}} >
                   <div>
                    <span className = "dBlog">(로고)디네이션 블로그</span> 
                    </div></Card>   
                 
                 <Card style = {{marginBottom : '2vh', padding : '1vh', paddingTop : '2.2vh'}} >
                   <div>
                    <span className = "dCafe">(로고)디네이션 카페</span>
                    </div> </Card>   
                 
                 
                 <Card style = {{marginBottom : '2vh', padding : '1vh', paddingTop : '1vh'}} > 
                 


                  <div className='channelWrap' onClick={you}>
                    <img src='./youtube-Logo.png' className='youtubeLogo' />
                    <span className="dyoutube"> 디네이션 유튜브  </span>
                  </div>
                 </Card>   
                  */}
                 
                  

              </Col>
            </Row>


            </div>
        </div>
      </Card>
    </div>
  )
}

export default Road
