import React, { useEffect }  from 'react'
import './mainFirst.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';

import AOS from 'aos'
import "aos/dist/aos.css"




const Main = () => {

    useEffect(() => {
      AOS.init(
        
      )
    })
}
  

const MainFirst = () => {
    return (

        <>
            <div className='mainFisrtBody' >
                    <Row xs={1} md={1} >

                        <Col style ={{ textAlign : 'center'}}>
                            <div className='mainFcontext' >
                            <span className='safeLife'>CCTV</span><br/>
                            <span className='safeLife'>안전한 삶을 설계하다.</span>
                            </div>
                        </Col>


                        <Col style ={{ textAlign : 'center'   }}>
                        <div className='mainFcompany'   >
                            <span className='mainFCompanyname'>(주)디네이션</span>
                            </div>
                        </Col>

                        <Col style ={{ textAlign : 'center'}}>
                            <div className = "imageW" data-aos="zoom-out-up" data-aos-delay="50" data-aos-duration="800"  >
                            <img src="main_1.png" className='mainFirstimg'/>
                            </div>
                        </Col>

                    </Row>
            </div>

        </>



    )
}

export default MainFirst
