import React, { useEffect } from 'react'
import styled from 'styled-components'
import './introduce.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import AOS from 'aos'
import "aos/dist/aos.css"




const Introduce = () => {

  useEffect(() => {
    AOS.init(
    );
    
    window.addEventListener('load', AOS.refresh);

  })


  const DnationMV = () => {

    window.open(`https://youtu.be/JzRekTfU5jE`)
  }



  return (
    <>
    <div data-aos="fade-up"  data-aos-duration="1600"  data-aos-delay="0" >
      <Card style = {{ borderRadius: "30px" ,background: "#103F83", padding : "25px", margin : "17px", marginTop : "0" } }  > 
      {/* <div className='intro__Backgound_color'> */}


          {/* <div className='row home__hero-row' style={{ display: 'flex' }}   > */}

          <Row style = { { backgroundColor : '#103F83', justifyContent : 'center'  }  } >

              <Col  md={3} style = { {   marginBottom : '5vh', backgroundColor : '#103F83'}  }>
                <div className='introCCTV'>
                <span className='introCCTV1'>CCTV </span>
                </div>
                <div>

                <span className='introCCTV2'>안전한 삶을 </span>
                </div>
                <div>
                <span className='introCCTV3'>설계하다 </span>
                </div>


 




              </Col>



              {/* 기본 카드에서는 div를 먼저 잡고, 미디어쿼리는 자동으로돼니
              지정은 안해도 됌! 그러나 가운데를 따로 줘야할때는 미디어쿼리로 따로 해야함 */}

              <Col md={4}  style = { { backgroundColor : '#103F83', justifyContent : 'center' , display : 'flex'}  }>
                {/* <Card  style = { { borderRadius : '100px', width : '25vw', height : '70vh', marginTop : '5vh', marginBottom : '8vh' }  }> */}
              <div  className='introContentsContainer' data-aos="fade-up" data-aos-duration="700"   style={{ }}>
        

                
                <p className='introContents'  >안녕하세요</p>
                <p className='introContents'   >시큐리티 CCTV 영상 보안을 시작한지도 </p>
                <p className='introContents'   >13년이 지났습니다.</p>
                <p className='introContents'  >한 길만 걸어왔던 지금은</p>
                <p className='introContents'  >자연스럽게 다양한 고객, B2B 기업들과</p>
                <p className='introContents'  > <b>"같이"</b> </p>
                <p className='introContents'  >협업하면서</p>
                <p className='introContents'  > <b>"같이"</b> </p>
                <p className='introContents'  >상생 할 수 있었고</p>
                <p className='introContents'  > <b>"가치"</b></p>
                <p className='introContents'  >를 추구할 수 있었습니다.</p>
                <p className='introContents'  >그 "가치"는 바로 </p>
                <p className='introContents'  >통합 솔루션</p>
                <p className='introContents'  >설계부터 네트워크, 디스플레이, </p>                
                <p className='introContents'  >주차관제, 정보통신공사, 유지보수까지</p>                
                <p className='introContents'  >전문가들이 고객에 니즈에 맞는</p>                
                <p className='introContents'  >"맞춤형 서비스"를 제공함으로써,</p>                
                <p className='introContents'  ><b>고객의 "안전과 만족"을 </b></p>                
                <p className='introContents'  ><b>최우선으로 하고 있습니다. </b></p>                

                <p className='dnationCEO'><b>디네이션 대표 김덕웅</b></p>

                </div>
                {/* </Card> */}

              </Col>

   






              <Col md={4}  style = { { marginTop : '15vh' , backgroundColor : '#103F83', marginLeft : '2vw' }  }>

               <div className='introImgDivTest'   >
                <div data-aos="fade-right" className='introImgDiv'  >
                  <img src="dnation.jpg"  className='introImgSize' />
                </div>
                </div> 
                <p   className='introLogo'>디네이션 로고</p>

                <br/>
                <br/>

                <div className='introMVDiv' data-aos="fade-left" >
                {/* <img src="dnation.jpg"  /> */}
                {/* <video src="MV.mp4" controls autoplay muted loop /> */}
                
                  {/* <img src="MVIMG.png"  className='introMVSize' /> */}
                  <div className='introMViconBox' >
                  </div>
                  <img src="005.png"  className='introMVicon'  onClick={DnationMV} />

                </div>
                <p className='introLogo'>디네이션 홍보 영상</p>

                
              </Col>


           



            </Row>



        {/* </div> */}


      {/* </div> */}
      </Card>
      </div>
    </>
  )
}

export default Introduce
