import React, { useEffect } from 'react'
import './star.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';

import AOS from 'aos'
import "aos/dist/aos.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

import Carousel2 from 'react-elastic-carousel'
import Review from './review';



const Star = () => {


  useEffect(() => {
    AOS.init(
    )
  })



  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ]




  return (
    <>

      <div className='startContextBox'>

        
        <p className='starContexts1'
          data-aos="fade-up" data-aos-duration="800"
        >디네이션몰이 증명하고 있어요.</p>
      </div>

      <div className='startContextBoxIn'>

        <p className='starContexts2'
          data-aos="fade-up" data-aos-delay="200" data-aos-duration="400"
        >#고객님들의 후기로</p>
        <p className='starContexts2'
          data-aos="fade-up" data-aos-delay="250" data-aos-duration="450"
        >#디네이션과의 소통을</p>
      </div>





      <div className='boxLine'  >



        <Carousel2 breakPoints={breakPoints}>

          <Card style={{ borderColor: 'white' }} >
            <div className='box'>




              <div className='list'>


                <div className='imgBx'>
                  <img src="./star2.png" />
                </div>


                <div className='ReviewContent'>

                  <p className='ReviewContentText'>판매자님이 너무 친절하세요. 설치 때문에 늦은 시간에 톡톡으로 문의 드렸는데 성심성의껏 답변 해주시더라구요. (생략) 상품평이 좋은데는 다 이유가 있었네요! 정말 잘 사용 할 거 같습니다!</p>
                  <p className='ReviewContentText2'>디네이션 몰 후기</p>
                </div>



              </div>

            </div>
          </Card>


          <Card style={{ borderColor: 'white' }} >
            <div className='box'>


              <div className='list'>


                <div className='imgBx'>
                  <img src="./star2.png" />
                </div>


                <div className='ReviewContent'>
                  <p className='ReviewContentText'>주문하고 하루만에 잘 받아 설치했습니다. (생략) 친절하게 꼼꼼히 알려주셔서 감사했습니다. 마치 오프라인 매장에서 구입하는 느낌이었습니다. 카메라와 앱설치도 간단하고 쉽네요. 적극 추천합니다.</p>
                  <p className='ReviewContentText2'>디네이션 몰 후기</p>
                </div>



              </div>




            </div>
          </Card>

          <Card style={{ borderColor: 'white' }} >
            <div className='box'>


              <div className='list'>


                <div className='imgBx'>
                  <img src="./star2.png" />
                </div>


                <div className='ReviewContent'>
                  <p className='ReviewContentText'>좋아요
                    판매자께서 매우 친절하고
                    응대도 잘해주세요!</p>
                  <p className='ReviewContentText2'>디네이션 몰 후기</p>
                </div>



              </div>




            </div>
          </Card>

          <Card style={{ borderColor: 'white' }} >
            <div className='box'>


              <div className='list'>


                <div className='imgBx'>
                  <img src="./star2.png" />
                </div>


                <div className='ReviewContent'>
                  <p className='ReviewContentText'>작고 귀엽고 화면도 선명하고 좋아여!!
                    그리고 제가 이런거 연결하고 그런걸 잘못해서 저녁에 문의 드렸는데 바로 답변 주시더라구여
                    퇴근 하셨을 시간인데 너무 감사했고, 아침에도 질문 드렸는데 너무 친절하게 설명해주셨습니다.
                    강력 추천드립니다!! 잘 쓸께요!!
                  </p>
                  <p className='ReviewContentText2'>디네이션 몰 후기</p>
                </div>



              </div>




            </div>
          </Card>











        </Carousel2>

        <Review />
      </div>
    </>
  )
}

export default Star



