import React, { useEffect }  from 'react'
import './reference.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import AOS from 'aos'
import "aos/dist/aos.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

import Carousel3 from 'react-elastic-carousel'





const Reference = () => {


  useEffect(() => {
    AOS.init(
    )
  })



  const breakPoints = [
    {width : 1, itemsToShow : 1},
    {width : 500, itemsToShow : 2},
    {width : 768, itemsToShow : 3},
    {width : 1200, itemsToShow : 5},
]



  return (
    <>


        <p  className='RoadWait' style = {{ textAlign : 'center', backgroundColor : '', margin : '0', marginTop : '150px'}}  data-aos="fade-up" data-aos-duration="800"  data-aos-delay="0">
          오직</p>

          <p className='RoadWait2'    style = {{ textAlign : 'center'}}  data-aos="fade-up" data-aos-duration="800"  data-aos-delay="200"> <span className='social1'>고객</span>만을 위한 <span className='social2'>가치</span>   </p>
          
      <Card data-aos="fade-up" data-aos-duration="750" data-aos-delay="400"
       style = {{ borderRadius: "30px" ,background: "#14487B", textAlign : "center", borderColor : "grey" } }  > 

            
            <p className='referenceText2'    > 디네이션 <span className='referenceText2re'> 레퍼런스</span>   </p>

            

              {/* <Row xs={1}  sm= {2} md={5} className="g-4" > */}


              <Carousel3  breakPoints={breakPoints}>
                  
               
                            <div  className = "cardWrapperRefer"  >
                                
                                <div className = "CardImgRe">
                                   <img src="refer1.jpg"  className='CardImgRe2'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    <p className='ImgTitle'>시공 사진 내역 </p>
                                    <p className='ImgContent'>시공 사진 내역 </p>
                                    
                                </div>
                            </div>

               
                            <div  className = "cardWrapperRefer"  >
                                
                                <div className = "CardImgRe">
                                   <img src="refer2.jpg"  className='CardImgRe2'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    <p className='ImgTitle'>시공 사진 내역 </p>
                                    <p className='ImgContent'>시공 사진 내역 </p>
                                    
                                </div>
                            </div>

               
                            <div  className = "cardWrapperRefer"  >
                                
                                <div className = "CardImgRe">
                                   <img src="refer3.jpg"  className='CardImgRe2'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    <p className='ImgTitle'>시공 사진 내역 </p>
                                    <p className='ImgContent'>시공 사진 내역 </p>
                                    
                                </div>
                            </div>

               
                            <div  className = "cardWrapperRefer"   >
                                
                                <div className = "CardImgRe">
                                   <img src="refer4.jpg"  className='CardImgRe2'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    <p className='ImgTitle'>시공 사진 내역 </p>
                                    <p className='ImgContent'>시공 사진 내역 </p>
                                    
                                </div>
                            </div>

               
                            <div  className = "cardWrapperRefer"  >
                                
                                <div className = "CardImgRe">
                                   <img src="refer5.jpg"  className='CardImgRe2'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    <p className='ImgTitle'>시공 사진 내역 </p>
                                    <p className='ImgContent'>시공 사진 내역 </p>
                                    
                                </div>
                            </div>
               
               
                            <div  className = "cardWrapperRefer" >
                                
                                <div className = "CardImgRe">
                                   <img src="refer6.jpg"  className='CardImgRe2'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    <p className='ImgTitle'>시공 사진 내역 </p>
                                    <p className='ImgContent'>시공 사진 내역 </p>
                                    
                                </div>
                            </div>



                             

                      </Carousel3>
               
              {/* </Row> */}




        <div className='referenceLogoWrap2'>
          <div className='referenceLogoWrap'>
            <img src='./006.png' className='referenceLogo' />

          </div>
        </div>

      </Card>
    </>
  )
}

export default Reference
