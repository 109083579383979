import React, { useEffect }  from 'react'
import styled from 'styled-components'
import './bori.css'
import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import AOS from 'aos'
import "aos/dist/aos.css"


const Introduce = () => {


  useEffect(() => {
    AOS.init(
    )
  })


  return (
    <>

<div className='boriFbodyTitle'>
        <p  className='borib' style = {{ textAlign : 'center'}}  data-aos="fade-up" data-aos-duration="1200"  data-aos-delay="0" >
          두근두근.</p>

        <p style = {{color : 'black', textAlign : 'center'}}>
          <span className='boriPlan2'  style = {{ }} 
          data-aos="fade-up" data-aos-duration="700"  data-aos-delay="300" >
            곧 나와요!</span>
            </p>
          <p className='borilun'  style = {{color : 'black', textAlign : 'center', margin : '30px'}} 
          data-aos="fade-up" data-aos-duration="700"  data-aos-delay="300" >
            디네이션 애견캠 브랜드 런칭!</p>

    </div>


       <div className='boriBodyW'  data-aos="fade-up" data-aos-duration="700"  data-aos-delay="500" >
      <Card style = {{ borderRadius: "30px" ,background: "white", padding : "50px", textAlign : "center", borderColor : "grey", paddingTop : "80px"} }  > 
      {/* <div className='intro__Backgound_color'> */}


          {/* <div className='row home__hero-row' style={{ display: 'flex' }}   > */}



                <div className='' style={{  }}>
                    
                <Row >
                    <Col md={3 } sytle = {{  }}>
                        <p className='bori1'
                         data-aos="fade-up" data-aos-duration="1100"  data-aos-delay="0"
                        >#24시간</p>
                    </Col>
                    <Col md={3}   sytle = {{  }}>
                        <p className='bori2'
                         data-aos="fade-up" data-aos-duration="1100"  data-aos-delay="100"
                        >#지켜보다.</p>
                    </Col>
                    <Col md={3}   sytle = {{  }}>
                        <p className='bori3'
                         data-aos="fade-up" data-aos-duration="800"  data-aos-delay="400"
                        >#사랑하는 우리집 댕댕이</p>
                    </Col>

                    <Col md={3}  >
                        
                        <p className='bori4'
                        data-aos="fade-up" data-aos-delay="400" data-aos-duration="800"
                        >보리야</p>
                    </Col>
                    
                </Row>

                </div >




<Row>

              <div >
                {/* <h1 className=''>보리 이미지</h1> */}
                <img src="boriImg.png" />
   
                </div>
                
              
</Row>

<Row>

              <div >
                {/* <h1 className=''>보리 이미지</h1> */}
                    <span className='boriPlan'>2022년</span>

                    <div   >
                    <span className='boriPlan2'  data-aos="zoom-in"  data-aos-duration="900" > 출시예정</span>
                    </div>
                </div>
                
              
</Row>
   



        {/* </div> */}


      {/* </div> */}
      </Card>
      </div>
    </>
  )
}

export default Introduce
