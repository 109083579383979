import React from 'react'
import './review.css'

import { CardGroup, Card, Carousel, Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';

const Review = () => {
  return (






    
      <section >
    <div  className='row' >


          <div>
          
          <i className='review1' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>
          
              <i className='review2' aria-hidden="true" >
                  <img  className='reviewImg' src='2re.png' />
              </i>

              <i className='review3' aria-hidden="true" >
                  <img  className='reviewImg' src='3re.png' />
              </i>

              <i className='review4' aria-hidden="true" >
                  <img  className='reviewImg' src='4re.png' />
              </i>

              <i className='review5' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review6' aria-hidden="true" >
                  <img  className='reviewImg' src='2re.png' />
              </i>

              <i className='review7' aria-hidden="true" >
                  <img  className='reviewImg' src='3re.png' />
              </i>

              <i className='review8' aria-hidden="true" >
                  <img  className='reviewImg' src='4re.png' />
              </i>


      </div>

{/*         
      <div>
      <i className='review1' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review2' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review3' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review4' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review5' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review6' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review7' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>

              <i className='review8' aria-hidden="true" >
                  <img  className='reviewImg' src='1re.png' />
              </i>


      </div> */}




    </div>


    </section>
  )
}

export default Review



