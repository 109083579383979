import React, {useState} from 'react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'

//아이콘
import { FaBars, FaTimes, FaPhone } from 'react-icons/fa';

import { BsList , BsTelephoneForwardFill, BsXCircleFill } from "react-icons/bs";

//nav컬러
import './Navbar.css'

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import {Nav, CardGroup, Card, Carousel, Button, Row, Col, CarouselItem } from 'react-bootstrap';





const Navbar = () => {

  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)


  const closeMmenu = () => setClick(false)




  return (
    <div className='header'>
    <div className='navbar'>
      {/* public은 이미 이미지가 있기 때문에 경로 지정안해도 됌 */}


      <div className='navbar-container vbcontainer' >

        
        <Link to='/Main'> 
        <div className='navMlogo'>
        <img src="dnation.jpg" alt="logo" width="60%"  className='nvLogo' /></div>
        </Link>

        {/* 아래부분을 div로 묶으면 이미지 change가능함! */}
        <div className='menu-icon' onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>



        <div className='phoneIcon'>
          <a href="tel:15227219"><FaPhone /></a>
        </div>




        {/* 아래부분이 active관련 된 부부능로 연결됄 */}
        <ul className={click ? 'nav-menu active' : 'nav-menu'} >
          <li className='nav-item'>
            <Link to='test' className='nav-links' smooth={true} duration={250} offset={-80} onClick = {  closeMmenu}>디네이션</Link>
          </li>

          <li className='nav-item'>
            <Link to='mallReview' className='nav-links' smooth={true} duration={250} offset={-10} onClick = {  closeMmenu}>디네이션몰 후기 </Link>
          </li>



          <li className='nav-item'>
            <Link to='socialA' className='nav-links' smooth={true} duration={250} offset={-80}  onClick = {closeMmenu}>사회공헌</Link>
          </li>
          <li className='nav-item'>
            <Link to='reference' className='nav-links' smooth={true} duration={250} offset={-90} onClick = {closeMmenu} >레퍼런스</Link>
          </li>


          <li className='nav-item'>
            <Link to='bori' className='nav-links' smooth={true} duration={250} offset={-75} onClick = {closeMmenu} >보리캠</Link>
          </li>
          
          <li className='nav-item'>
            <Link to='qna' className='nav-links' smooth={true} duration={250} offset={-60}  onClick = {closeMmenu}>문의</Link>
          </li>


        </ul>

      </div>
    </div>
    </div>
  )
}


export default Navbar



