import React from 'react'
import styled from 'styled-components'
import './bottom.css'



const Bottom = () => {

  return (
        <div className='bottomContext'>
            <p className='bottomText1' >(주)디네이션
            </p>
            <p className='bottomText2' >대표전화 : 1522-7219
            </p>
            <p className='bottomText3' > 경기도 구리시 갈매동 552-12 구리갈매 금강펜테리움IX타워 타워동 9층 36호
            </p>
            <p className='bottomText3' > 대표 : 김덕웅     사업자등록번호 : 344-81-01719 
            </p>
            
            <p className='bottomText3' > 통신판매업신고번호 : 제2020-서울강북-0247호
            </p>
            
            <p className='bottomText3' > Email : dw_nation@naver.com  호스팅 : 가비아        </p>
            <p className='bottomText3' > COPYRIGHT. D-nation. All rights reserved. </p>
            
        </div>
  )
}


export default Bottom



