import React, {useEffect} from 'react'
import { CardGroup, Card,  Button, Row, Col, CarouselItem, Container } from 'react-bootstrap';
import './socialActive.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import Carousel from 'react-elastic-carousel'

import AOS from 'aos'
import "aos/dist/aos.css"



const SocialActive = () => {
  

    
    useEffect(() => {
        AOS.init(
        )
    })

    const breakPoints = [
            {width : 1, itemsToShow : 1},
            {width : 500, itemsToShow : 2},
            {width : 768, itemsToShow : 3},
            {width : 1200, itemsToShow : 3},
]


    return (
        <div className='socialbody'>
        {/* <div className='container'> */}

                <div className='socialTitle' style={{marginBottom : '15vh' }}>
                    
                <Row sytle = {{ }}>
                    <Col md={6}  sytle = {{  }}>
                        <p className='socialContexts1'
                         data-aos="fade-up" data-aos-duration="800"
                        >디네이션은 행동합니다.</p>
                    </Col>

                    <Col md={6} >
                        <p className='socialContexts2'
                        data-aos="fade-up" data-aos-delay="200" data-aos-duration="700"
                        >아름다운 세상을</p>
                        <p className='socialContexts2'
                        data-aos="fade-up" data-aos-delay="250" data-aos-duration="700"
                        >만들기 위해</p>
                    </Col>
                </Row>

                </div >
    
        <Row sytle = {{}}>
        <div className='socialImgBody'  data-aos="fade-up" data-aos-delay="10" data-aos-duration="700">
        <Carousel breakPoints={breakPoints}>
             
                            <div  className = "cardWrapper" >
                                <div className ="Card"></div>
                                <div className = "CardImg">
                                   <img src="social1.jpg"  className = "CardImgIn" />
                                </div>
                                <div classNAme = "CardDetail">
                                    {/* <h2>hihi  <span>gogo!!</span></h2> */}
                                </div>
                            </div>
                            <div  className = "cardWrapper" >
                                <div className = "CardImg">
                                <img src="social2.jpg"/>
                                </div>
                                <div classNAme = "CardDetail">
                                    {/* <h2>hihi  <span>gogo!!</span></h2> */}
                                </div>
                            </div>
                            <div  className = "cardWrapper" >
                                <div className = "CardImg">
                                <img src="social3.jpg"/>
                                </div>
                                <div classNAme = "CardDetail">
                                    {/* <h2>hihi  <span>gogo!!</span></h2> */}
                                </div>
                            </div>
                            <div  className = "cardWrapper" >
                                <div className = "CardImg">
                                <img src="social4.jpg"  className='socialImg' />
                                </div>
                                <div classNAme = "CardDetail">
                                    {/* <h2>hihi  <span>gogo!!</span></h2> */}
                                </div>
                            </div>
                            <div  className = "cardWrapper" >
                                <div className = "CardImg">
                                <img src="social5.jpg"  className='socialImg'/>
                                </div>
                                <div classNAme = "CardDetail">
                                    {/* <h2>hihi  <span>gogo!!</span></h2> */}
                                </div>
                            </div>

                            

                </Carousel>

            </div>
            </Row>
            <div className='socialimgTitle'>
                <p className='socialContexts3'  data-aos="fade-up" data-aos-delay="10" data-aos-duration="700">D-NATION 사회공헌 활동</p>
                </div>
             </div>
            
            
            )
        }
        
        export default SocialActive
        